import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from 'react';
import externalHttp from '../externalHttp';
import { sortBiddings } from 'utils/sortHelper';
const ExternalContext = createContext();
export const useExternalContext = () => {
  return useContext(ExternalContext);
};
export const ExternalProvider = ({ children }) => {
  const [biddings, setBiddings] = useState('');
  const [captchaData, setCaptchaData] = useState('');
  const [vesselSearchData, setVesselSearchData] = useState(null);
  const [agentSearchData, setAgentSearchData] = useState(null);
  const [lookupsData, setLookupsData] = useState(null);
  const [transactionStatusData, setTransactionStatusData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mainCategoriesData, setMainCategoriesData] = useState(null);
  const [subCatergoriesData, setSubCatergoriesData] = useState(null);
  const [movableData, setMovableData] = useState(null);

  const handleApiError = useCallback((error) => {
    console.error(error);
    setIsLoading(false);
  }, []);

  async function vesselSearch(data) {
    setVesselSearchData([]);
    if (!data) return;

    setIsLoading(true);
    await externalHttp
      .get('/searchVesselSchedule', {
        params: {
          TrafficType: data.Status,
          port: data.Port,
          ArrivalDate: data.StartDate,
          DepartureDate: data.EndDate,
          agent: data.AgentName,
          vessel: data.VesselName,
          captcha: data.captcha,
        },
      })
      .then((response) => {
        setVesselSearchData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setVesselSearchData(error.response.data);
        handleApiError(error);
      });
  }

  async function agentSearch(data) {
    setAgentSearchData([]);
    if (!data) return;

    await externalHttp
      .get('/searchAgents', {
        params: {
          port: data.Port,
          title: data.AgentName,
          captcha: data.captcha,
        },
      })
      .then((response) => {
        setAgentSearchData(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setAgentSearchData(error.response.data);
        handleApiError(error);
      });
  }

  async function getCaptcha() {
    await externalHttp
      .get('/captcha')
      .then((response) => {
        setCaptchaData(response.data?.data || []);
      })
      .catch(handleApiError);
  }

  async function getLookups() {
    if (lookupsData) return;
    await externalHttp
      .get('/lookups')
      .then((response) => {
        console.log(response);
        setLookupsData(response.data?.data || {});
      })
      .catch(handleApiError);
  }

  async function getBiddings() {
    if (biddings?.length > 0) return;
    externalHttp
      .get('/biddings')
      .then((response) => {
        const biddings = sortBiddings(response.data?.data || []);
        setBiddings(biddings);
        setIsLoading(false);
      })
      .catch(handleApiError);
  }

  async function getTransactionStatus({ DocumentNumber, HDate, captcha }) {
    setTransactionStatusData(null);
    if (!DocumentNumber || !HDate || !captcha) return;

    setIsLoading(true);
    await externalHttp
      .get('/transactionStatus', {
        params: {
          DocumentNumber,
          HDate,
          captcha,
        },
      })
      .then((response) => {
        setTransactionStatusData(response.data || {});
        setIsLoading(false);
      })
      .catch((error) => {
        setTransactionStatusData(error.response.data || {});
        handleApiError(error);
      });
  }

  async function submitJobsForm(data) {
    setIsLoading(true);
    return externalHttp
      .post('/applyJob', data)
      .then((response) => {
        setIsLoading(false);
        return response?.data;
      })
      .catch((error) => {
        handleApiError(error);
        return error?.response?.data;
      });
  }

  async function submitContactUsForm(data) {
    setIsLoading(true);
    return externalHttp
      .post('/contactUs', data)
      .then((response) => {
        setIsLoading(false);
        return response?.data;
      })
      .catch((error) => {
        handleApiError(error);
        return error?.response?.data;
      });
  }

  async function submitOrderForm(data) {
    setIsLoading(true);
    return externalHttp
      .post('/orderForm', data)
      .then((response) => {
        setIsLoading(false);
        return response?.data;
      })
      .catch((error) => {
        handleApiError(error);
        return error?.response?.data;
      });
  }

  async function getMainCategories() {
    if (mainCategoriesData?.length > 0) return;
    setIsLoading(true);
    await externalHttp
      .get('/mainCategories')
      .then((response) => {
        setMainCategoriesData(response?.data?.data || []);
        setIsLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  }

  async function getSubCategories({ mainCategory }) {
    setIsLoading(true);
    await externalHttp
      .get('/subCategories', {
        params: {
          maincategory: mainCategory,
        },
      })
      .then((response) => {
        setSubCatergoriesData(response?.data?.data || []);
        setIsLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  }

  async function getMovable({ subCategory }) {
    setIsLoading(true);
    await externalHttp
      .get('/movable', {
        params: {
          subCategory,
        },
      })
      .then((response) => {
        setMovableData(response?.data?.data || {});
        setIsLoading(false);
      })
      .catch((error) => {
        handleApiError(error);
      });
  }
  async function submitRemarksForm(data) {
    setIsLoading(true);
    return externalHttp
      .post('/remarks', data)
      .then((response) => {
        setIsLoading(false);
        return response?.data;
      })
      .catch((error) => {
        handleApiError(error);
        return error?.response?.data;
      });
  }

  const value = {
    biddings,
    vesselSearch,
    vesselSearchData,
    agentSearch,
    agentSearchData,
    apiLoading: isLoading,
    getCaptcha,
    captchaData,
    lookupsData,
    getLookups,
    getBiddings,
    getTransactionStatus,
    transactionStatusData,
    submitJobsForm,
    submitContactUsForm,
    submitOrderForm,
    mainCategoriesData,
    subCatergoriesData,
    movableData,
    getMainCategories,
    getSubCategories,
    getMovable,
    submitRemarksForm,
  };

  return (
    <ExternalContext.Provider value={value}>
      {children}
    </ExternalContext.Provider>
  );
};
