import { useEffect, useState } from 'react';
import styles from './Announcement.module.scss';
import { useTranslation } from 'react-i18next';

const AnnouncementComp = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
    }, []);

    return (
        <>
            <div className={`${styles.bgHeaderBackground}`}></div>
            <div className="container_max">
                <div className="page_wrapper">
                    <h1 className="page_title">
                        {t('Announcements')}
                    </h1>
                </div>
            </div><div className="overflow-hidden">
                <div className="container_max">
                    <div className="page_wrapper_sub">
                        <div className={`${styles.title}`}>{t('PopUpTitle')}</div>
                        {/* <div className={`${styles.subTitle}`}>{t('PopUpTitleTwo')}</div> */}
                        <div className={`${styles.announcement}`}>{t('PopUpAnnouncement')}</div>
                        <div className={`${styles.announcement}`}>{t('PopUpAnnouncementTwo')}</div>
                        {/* <div className={`${styles.afterAnnouncement}`}>{t('PopUpBless')}</div> */}
                        <div className={`${styles.subTitle}`}>يشترط الدخول للمزاد التقيد بالضوابط التالية:</div>
                        {/* <div className={`${styles.table}`}> */}
                        <ol className={`${styles.bullet}`}>
                            <div>
                                1- يجب على من ترسو علية المزايدة تقديم ضمان بنكي قدرة (5%) من قيمتها
                                ويجوز قبول الشيك المصرفي أو مبلغ نقدي كضمان في المزايدة العلنية .
                            </div>
                            <div>
                                2- قيمة البيع مضاف اليها ضريبة القيمة المضافة .
                            </div>
                            <div>
                                3- دفع عمولة السعي وقدرها 2,5% من قيمة المزايدة غير شامل ضريبة القيمة
                                المضافة.
                            </div>
                            <div>
                                4- دفع القيمة كاملة شامل ضريبة القيمة المضافة بعد موافقة صاحب الصلاحية
                                على استكمال إجراءات البيع.
                            </div>
                            <div>
                                5- يتم ارجاع الضمان البنكي بعد إخراج الأصناف المباعة خارج الميناء خلال
                                المدة المحددة نظاماً .
                            </div>
                            <div>
                                6- يجب التقيد بما ورد بنظام المنافسات والمشتريات الحكومية ولائحته التنفيذية
                                الباب الخامس (بيع المنقولات) .
                            </div>
                            <div>
                                7- تخضع هذه المزايدة لنظام المنافسات والمشتريات الحكومية ولائحته التنفيذية.
                            </div>
                        </ol>

                        {/*   <div>
                                <h1>مرفق صيغة الاعلان ومواصفات الحوض العائم رقم(1)</h1>
                                <table>
                                    <tr>
                                        <th>VESSEL NAME</th>
                                        <td>FLOATING DOCK 19000 DWT</td>
                                    </tr>
                                    <tr>
                                        <th>TYPE OF VESSEL</th>
                                        <td>FLOATING DOCK</td>
                                    </tr>
                                    <tr>
                                        <th>CLASS</th>
                                        <td>-</td>
                                    </tr>
                                    <tr>
                                        <th>Length over pontoon</th>
                                        <td>215.00 m</td>
                                    </tr>
                                    <tr>
                                        <th>Length over all</th>
                                        <td>200.00</td>
                                    </tr>
                                    <tr>
                                        <th>Free inside width between dock runways</th>
                                        <td>33.50 m</td>
                                    </tr>
                                    <tr>
                                        <th>DEPTH (MLD)</th>
                                        <td>15.90 m</td>
                                    </tr>
                                    <tr>
                                        <th>DEPTH OF PONTOON (MLD)</th>
                                        <td>4.10 m</td>
                                    </tr>
                                    <tr>
                                        <th>Draft without load</th>
                                        <td>3.70 m</td>
                                    </tr>
                                    <tr>
                                        <th>MAXIMUM IMMERSION DRAUGHT</th>
                                        <td>13.4m</td>
                                    </tr>
                                    <tr>
                                        <th>LIGHTSHIP</th>
                                        <td>7149.84 Tonnes</td>
                                    </tr>
                                    <tr>
                                        <th>LCG</th>
                                        <td>100.4m-AP</td>
                                    </tr>
                                    <tr>
                                        <th>VCG</th>
                                        <td>6.85m</td>
                                    </tr>
                                </table>
                            </div> */}
                        {/* <div>
                                <h1>مواصفات القطعة البحرية (الوحدة العائمة دمام 1)</h1>
                                <table>
                                    <tr>
                                        <th>Name Of Vessel</th>
                                        <td>DAMMAM – 1</td>
                                        <td>دمام - 1</td>
                                        <th className={`${styles.arabicHeader}`}>إسم القطعة البحرية</th>
                                    </tr>
                                    <tr>
                                        <th>Official No.</th>
                                        <td>725</td>
                                        <td>725</td>
                                        <th className={`${styles.arabicHeader}`}>الرقم الرسمي</th>
                                    </tr>
                                    <tr>
                                        <th>Type Of Vessel</th>
                                        <td>-</td>
                                        <td>ونش عائم</td>
                                        <th className={`${styles.arabicHeader}`}>نوع السفينة</th>
                                    </tr>
                                    <tr>
                                        <th>Place Of Build</th>
                                        <td>GERMANY</td>
                                        <td>ألمانيا</td>
                                        <th className={`${styles.arabicHeader}`}>مكان البناء</th>
                                    </tr>
                                    <tr>
                                        <th>Classed By</th>
                                        <td>ABS</td>
                                        <td>ABS</td>
                                        <th className={`${styles.arabicHeader}`}>شهادة التصنيف</th>
                                    </tr>
                                    <tr>
                                        <th>Call Sign</th>
                                        <td>HZG 0066</td>
                                        <td>HZG 0066</td>
                                        <th className={`${styles.arabicHeader}`}>حروف التميز</th>
                                    </tr>
                                    <tr>
                                        <th>Length Over All</th>
                                        <td>52,62 M</td>
                                        <td>52,62 متر</td>
                                        <th className={`${styles.arabicHeader}`}>الطول الكلي</th>
                                    </tr>
                                    <tr>
                                        <th>Breadth</th>
                                        <td>24,51 M</td>
                                        <td>24,51 متر</td>
                                        <th className={`${styles.arabicHeader}`}>العرض</th>
                                    </tr>
                                    <tr>
                                        <th>Depth</th>
                                        <td>2,601 M</td>
                                        <td>2,601 متر</td>
                                        <th className={`${styles.arabicHeader}`}>العمق</th>
                                    </tr>
                                    <tr>
                                        <th>Draught</th>
                                        <td>-</td>
                                        <td>-</td>
                                        <th className={`${styles.arabicHeader}`}>الغاطس</th>
                                    </tr>
                                    <tr>
                                        <th>Engine Hourse Power</th>
                                        <td>977,26 K.W</td>
                                        <td>977,26 كيلو واط</td>
                                        <th className={`${styles.arabicHeader}`}>قوة الماكينات</th>
                                    </tr>
                                    <tr>
                                        <th>No. Of Propellers</th>
                                        <td>2</td>
                                        <td>2</td>
                                        <th className={`${styles.arabicHeader}`}>عدد الرفاصات</th>
                                    </tr>
                                    <tr>
                                        <th>Gross Tonnage</th>
                                        <td>1443 TONS</td>
                                        <td>1443 طن</td>
                                        <th className={`${styles.arabicHeader}`}>الحمولة الكلية</th>
                                    </tr>
                                    <tr>
                                        <th>Nott Tonnage</th>
                                        <td>1101 TONS</td>
                                        <td>1101 طن</td>
                                        <th className={`${styles.arabicHeader}`}>الحمولة الصافية</th>
                                    </tr>
                                </table>
                            </div>
                             <div>
                                <h1>مواصفات القطعة البحرية قارب المسح (دمام 6)</h1>
                                <table>
                                    <tr>
                                        <th>Name Of Vessel</th>
                                        <td>DAMMAM – 6</td>
                                        <td>دمام - 6</td>
                                        <th className={`${styles.arabicHeader}`}>إسم القطعة البحرية</th>
                                    </tr>
                                    <tr>
                                        <th>Official No.</th>
                                        <td>403/412</td>
                                        <td>403/412</td>
                                        <th className={`${styles.arabicHeader}`}>الرقم الرسمي</th>
                                    </tr>
                                    <tr>
                                        <th>Call Sign</th>
                                        <td>HZG 0/26</td>
                                        <td>HZG 0/26</td>
                                        <th className={`${styles.arabicHeader}`}>حروف التميز</th>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td>SURUEY BOAT</td>
                                        <td>قارب مسح بحري</td>
                                        <th className={`${styles.arabicHeader}`}>طبيعة عمل السفينة</th>
                                    </tr>
                                    <tr>
                                        <th>Port Of Registry</th>
                                        <td>DAMMAM</td>
                                        <td>الدمام</td>
                                        <th className={`${styles.arabicHeader}`}>ميناء التسجيل</th>
                                    </tr>
                                    <tr>
                                        <th>Date Registered</th>
                                        <td>-</td>
                                        <td>-</td>
                                        <th className={`${styles.arabicHeader}`}>تاريخ التسجيل</th>
                                    </tr>
                                    <tr>
                                        <th>Built By</th>
                                        <td>-</td>
                                        <td>حان هاتون</td>
                                        <th className={`${styles.arabicHeader}`}>إسم مصنع البناء</th>
                                    </tr>
                                    <tr>
                                        <th>Date Keel Laid</th>
                                        <td>1975</td>
                                        <td>1975</td>
                                        <th className={`${styles.arabicHeader}`}>تاريخ مد القرينة </th>
                                    </tr>
                                    <tr>
                                        <th>Place Of Build</th>
                                        <td>HOLLAND</td>
                                        <td>هولندا</td>
                                        <th className={`${styles.arabicHeader}`}>مكان البناء</th>
                                    </tr>
                                    <tr>
                                        <th>Classed By</th>
                                        <td>-</td>
                                        <td>-</td>
                                        <th className={`${styles.arabicHeader}`}>شهادة التصنيف</th>
                                    </tr>
                                    <tr>
                                        <th>Call Sign</th>
                                        <td>-</td>
                                        <td>-</td>
                                        <th className={`${styles.arabicHeader}`}>حروف التميز</th>
                                    </tr>
                                    <tr>
                                        <th>Length Over All</th>
                                        <td>15,70 M</td>
                                        <td>15,70 متر</td>
                                        <th className={`${styles.arabicHeader}`}>الطول الكلي</th>
                                    </tr>
                                    <tr>
                                        <th>Breadth</th>
                                        <td>4,90 M</td>
                                        <td>4,90 متر</td>
                                        <th className={`${styles.arabicHeader}`}>العرض</th>
                                    </tr>
                                    <tr>
                                        <th>Depth</th>
                                        <td>1,35 M</td>
                                        <td> 1,35متر</td>
                                        <th className={`${styles.arabicHeader}`}>العمق</th>
                                    </tr>
                                    <tr>
                                        <th>No. Of Masts</th>
                                        <td>1</td>
                                        <td>1</td>
                                        <th className={`${styles.arabicHeader}`}>عدد الصواري</th>
                                    </tr>
                                    <tr>
                                        <th>No. Of Decks</th>
                                        <td>1</td>
                                        <td>1</td>
                                        <th className={`${styles.arabicHeader}`}>عدد الأسطح</th>
                                    </tr>
                                    <tr>
                                        <th>Draught</th>
                                        <td>1,35 M</td>
                                        <td>1,35 متر</td>
                                        <th className={`${styles.arabicHeader}`}>الغاطس</th>
                                    </tr>
                                    <tr>
                                        <th>Engine Hourse Power</th>
                                        <td>977,26 K.W</td>
                                        <td>977,26 كيلو واط</td>
                                        <th className={`${styles.arabicHeader}`}>قوة الماكينات</th>
                                    </tr>
                                    <tr>
                                        <th>No. Of Propellers</th>
                                        <td>2</td>
                                        <td>2</td>
                                        <th className={`${styles.arabicHeader}`}>عدد الرفاصات</th>
                                    </tr>
                                    <tr>
                                        <th>Gross Tonnage</th>
                                        <td>40 TONS</td>
                                        <td>40 طن</td>
                                        <th className={`${styles.arabicHeader}`}>الحمولة الكلية</th>
                                    </tr>
                                    <tr>
                                        <th>No. Of Masts</th>
                                        <td>40 TONS</td>
                                        <td>40 طن</td>
                                        <th className={`${styles.arabicHeader}`}>الحمولة الصافية</th>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <h1>مواصفات القطعة البحرية (قارب مكافحة التلوث دمام - 40)</h1>
                                <table>
                                    <tr>
                                        <th>Name Of Vessel</th>
                                        <td>DAMMAM – 40</td>
                                        <td>دمام - 40  </td>
                                        <th className={`${styles.arabicHeader}`}>إسم القطعة البحرية</th>
                                    </tr>
                                    <tr>
                                        <th>Official No.</th>
                                        <td>404/845</td>
                                        <td>404/845</td>
                                        <th className={`${styles.arabicHeader}`}>الرقم الرسمي</th>
                                    </tr>
                                    <tr>
                                        <th>Type Of Vessel</th>
                                        <td>-</td>
                                        <td>سفينة مكافحة التلوث</td>
                                        <th className={`${styles.arabicHeader}`}>نوع السفينة</th>
                                    </tr>
                                    <tr>
                                        <th>Place Of Build</th>
                                        <td>DAMMAM</td>
                                        <td>الدمام</td>
                                        <th className={`${styles.arabicHeader}`}>مكان البناء</th>
                                    </tr>
                                    <tr>
                                        <th>Classed By</th>
                                        <td>ABS</td>
                                        <td>ABS</td>
                                        <th className={`${styles.arabicHeader}`}>شهادة التصنيف</th>
                                    </tr>
                                    <tr>
                                        <th>Call Sign</th>
                                        <td>HZG 5136</td>
                                        <td>HZG 5136</td>
                                        <th className={`${styles.arabicHeader}`}>حروف التميز</th>
                                    </tr>
                                    <tr>
                                        <th>Length Over All</th>
                                        <td>22 M</td>
                                        <td>22 متر</td>
                                        <th className={`${styles.arabicHeader}`}>الطول الكلي</th>
                                    </tr>
                                    <tr>
                                        <th>Breadth</th>
                                        <td>8,51 M</td>
                                        <td>8,51 متر</td>
                                        <th className={`${styles.arabicHeader}`}>العرض</th>
                                    </tr>
                                    <tr>
                                        <th>Depth</th>
                                        <td>3,28 M</td>
                                        <td>3,28 متر</td>
                                        <th className={`${styles.arabicHeader}`}>العمق</th>
                                    </tr>
                                    <tr>
                                        <th>Draught</th>
                                        <td>-</td>
                                        <td>-</td>
                                        <th className={`${styles.arabicHeader}`}>الغاطس</th>
                                    </tr>
                                    <tr>
                                        <th>Engine Hourse Power</th>
                                        <td>1744 HP</td>
                                        <td>1744 HP</td>
                                        <th className={`${styles.arabicHeader}`}>قوة الماكينات</th>
                                    </tr>
                                    <tr>
                                        <th>No. Of Propellers</th>
                                        <td>2</td>
                                        <td>2</td>
                                        <th className={`${styles.arabicHeader}`}>عدد الرفاصات</th>
                                    </tr>
                                    <tr>
                                        <th>Gross Tonnage</th>
                                        <td>170 TONS</td>
                                        <td>170 طن</td>
                                        <th className={`${styles.arabicHeader}`}>الحمولة الكلية</th>
                                    </tr>
                                    <tr>
                                        <th>Nott Tonnage</th>
                                        <td>51 TONS</td>
                                        <td>51 طن</td>
                                        <th className={`${styles.arabicHeader}`}>الحمولة الصافية</th>
                                    </tr>
                                </table>
                            </div>
                            <div>
                                <h1>مواصفات الحوض (دمام 2)</h1>
                                <table>
                                    <tr>
                                        <th>Name Of Vessel</th>
                                        <td>DAMMAM – 2</td>
                                        <td>دمام - 2</td>
                                        <th className={`${styles.arabicHeader}`}>إسم القطعة البحرية</th>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td>FLOATING DOCK</td>
                                        <td>حوض عائم</td>
                                        <th className={`${styles.arabicHeader}`}>نوع الحوض</th>
                                    </tr>
                                    <tr>
                                        <th>Imo.No</th>
                                        <td>8111829</td>
                                        <td>8111829</td>
                                        <th className={`${styles.arabicHeader}`}>رقم المنظمة البحرية</th>
                                    </tr>
                                    <tr>
                                        <th>Flag</th>
                                        <td>SAUDI ARABIA</td>
                                        <td>المملكة العربية السعودية</td>
                                        <th className={`${styles.arabicHeader}`}>العلم</th>
                                    </tr>
                                    <tr>
                                        <th>Gross Tonneo</th>
                                        <td>16266</td>
                                        <td>16266 طن</td>
                                        <th className={`${styles.arabicHeader}`}>الحمولة الكلية</th>
                                    </tr>
                                    <tr>
                                        <th>Length Over All</th>
                                        <td>165 m</td>
                                        <td>165 متر</td>
                                        <th className={`${styles.arabicHeader}`}>الطول الكلي</th>
                                    </tr>
                                    <tr>
                                        <th>Whidh</th>
                                        <td>33 m</td>
                                        <td>33 متر</td>
                                        <th className={`${styles.arabicHeader}`}>العرض</th>
                                    </tr>
                                    <tr>
                                        <th>Depth</th>
                                        <td>14,8 m</td>
                                        <td>14,8 متر</td>
                                        <th className={`${styles.arabicHeader}`}>العمق</th>
                                    </tr>
                                    <tr>
                                        <th>Owner</th>
                                        <td>KING ABDULAZIZ PORT IN DAMMAM  SAUDI ARABIA</td>
                                        <td>ميناء الملك عبدالعزيز بالدمام  المملكة العربية السعودية</td>
                                        <th className={`${styles.arabicHeader}`}>إسم المالك</th>
                                    </tr>
                                    <tr>
                                        <th>Place Of Build</th>
                                        <td>GERMANY</td>
                                        <td>ألمانيا</td>
                                        <th className={`${styles.arabicHeader}`}>بلد المنشأ</th>
                                    </tr>
                                    <tr>
                                        <th>No. Of Propellers:</th>
                                        <td>2</td>
                                        <td>2</td>
                                        <th className={`${styles.arabicHeader}`}>عدد الرفاصات</th>
                                    </tr>
                                    <tr>
                                        <th>Date Of Build</th>
                                        <td>1982</td>
                                        <td>1982م</td>
                                        <th className={`${styles.arabicHeader}`}>سنة البناء</th>
                                    </tr>
                                </table>
                            </div> */}
                        {/* </div> */}
                    </div>
                </div>
            </div >
        </>
    );
};

export default AnnouncementComp;
