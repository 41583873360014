import Navbar from 'components/Navbar';
import DefaultFooter from 'components/Footer';
import Header from 'components/home/Header';
import ServiceSection from 'components/home/ServiceSection';
import MediaSection from 'components/home/MediaSection';
import States from 'components/home/States';
import ImageCarousel from 'components/home/ImageCarousel';
import HistoryCarousel from 'components/home/HistoryCarousel';
import KeepMeInformed from 'components/home/KeepMeInformed/index';
import Information from 'components/home/Information';
import Subscribe from 'components/home/Subscribe';
import Map from 'components/home/Map';
import PopUp from 'components/home/PopUp';

export default function Home() {
  return (
    <>
      <Navbar />
      <Header />
      <States />
      {/* <PopUp /> */}
      <ImageCarousel />
      <Map />
      <HistoryCarousel />
      <ServiceSection />
      <MediaSection />
      {/* <KeepMeInformed /> */}
      <Information />
      <Subscribe />
      <DefaultFooter />
    </>
  );
}
